import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCombobox,{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Search for an option","multiple":"","small-chips":"","hide-details":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c('span',{staticClass:"subheading"},[_vm._v("Create")]),_c(VChip,{attrs:{"color":("" + (_vm.colors[_vm.nonce - 1])),"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c(VChip,_vm._b({attrs:{"color":("" + (item.color)),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c(VIcon,{attrs:{"small":"","icon":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c(VTextField,{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c(VChip,{attrs:{"color":("" + (item.color)),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c(VSpacer),_c(VListItemAction,{on:{"click":function($event){$event.stopPropagation();}}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.editing !== item ? _vm.icons.mdiPencil : _vm.icons.mdiCheck))])],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }